body{
    margin: 0;
}
.DDMainContainer {
    width: 100%!important;
    min-width: 100px!important;
}
@font-face {
    font-family: 'ModernEra';
    src: url("./Assets/fonts/ModerEra/ModernEraRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMono';
    src: url("./Assets/fonts/ModerEra/ModernEraMonoRegular.ttf");
}

@font-face {
    font-family: 'ModernEraMonoBold';
    src: url("./Assets/fonts/ModerEra/ModernEraMonoBold.ttf");
}

@font-face {
    font-family: 'ModernEraBold';
    src: url("./Assets/fonts/ModerEra/ModernEraBold.ttf");
}

@font-face {
    font-family: 'CooperBT';
    src: url("./Assets/fonts/Cooper/CooperLtBT-Regular.ttf");
}

@font-face {
    font-family: 'QuincyCFBold';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Bold.woff");
}

@font-face {
    font-family: 'QuincyCFMedium';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Medium.woff");
}

@font-face {
    font-family: 'QuincyCF';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-Regular.woff");
}

@font-face {
    font-family: 'QuincyCFItalic';
    src: url("./Assets/fonts/QuincyCF/QuincyCF-RegularItalic.woff");
}

@font-face {
    font-family: 'Inter Regular';
    src: url("./Assets/fonts/Inter/Inter-Regular.ttf");
}

@font-face {
    font-family: 'Inter Bold';
    src: url("./Assets/fonts/Inter/Inter-Bold.ttf");
}

@font-face {
    font-family: 'Poppins Medium';
    src: url("./Assets/fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: 'Poppins Bold';
    src: url("./Assets/fonts/Poppins/Poppins-Bold.ttf");
}

@font-face {
    font-family: 'Work Sans Regular';
    src: url("./Assets/fonts/WorkSans/WorkSans-Regular.ttf");
}

@font-face {
    font-family: 'Work Sans Bold';
    src: url("./Assets/fonts/WorkSans/WorkSans-Bold.ttf");
}