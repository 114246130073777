h1,
p {
  margin: 0;
  padding: 0;
}

.modal-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  z-index: 9999999;
}

.modal-main {
  background-color: #ffffff;
  border-radius: 16px;
  height: auto;
  text-align: center;
  width: 312px;
}

.modal-main-card-holder {
  background-color: #ffffff;
  border-radius: 16px;
  height: 514px;
  text-align: center;
  width: 312px;
}

.modal-main img {
  border-radius: 16px 16px 0px 0px;
}

.modal-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;
}

.modal-title {
  color: #212121;
  font-family: "Work Sans Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  line-height: 28px;
  width: 280px;
}

.modal-description {
  color: #616161;
  font-family: "Inter Regular", sans-serif;
  font-size: 14px;
  line-height: 20px;
  margin-top: 12px;
}

.modal-padding {
  margin-bottom: 24px;
}

.modal-price {
  color: #212121;
  font-family: "Work Sans Bold", sans-serif;
  font-size: 20px;
  line-height: 28px;
  margin-top: 16px;
}

.modal-discount {
  color: #616161;
  font-family: "Inter Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 4px 0 16px 0;
}

.modal-discount span {
  color: #cf1b45;
  text-decoration: line-through;
}

.modal-disclaimer {
  color: #616161;
  font-family: "Inter Regular", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
}

/* -------------- INFORMATIVE MODAL TEXT ------------- */

.modal-main_informative {
  background-color: #ffffff;
  border-radius: 16px;
  height: auto;
  text-align: center;
  width: 328px;
}
.modal-content_informative {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;

  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 9px 16px 24px 16px;
}
.modal-title_informative {
  align-items: flex-start;
  color: #212121;
  font-family: "Work Sans Bold", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  height: 56px;
  line-height: 28px;
  width: 80%;
  text-align: initial;
  margin: 24px auto 18px auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;

  /* Color/Gray scale/900 */
  color: #212121;
}

.modal-subtitle_informative {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  /* or 162% */
  display: flex;
  align-items: center;
  letter-spacing: 0.0015em;

  /* Color/Gray scale/800 */
  color: #424242;
  text-align: initial;
  margin-bottom: 24px;
}

.modal-description_informative {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.0025em;

  /* Color/Gray scale/800 */
  color: #424242;
  text-align: initial;
  margin-bottom: 16px;
}

.modal-description_informative span {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.0025em;

  /* Color/Gray scale/800 */
  color: #424242;
}
.modal-appointment_container {
  width: 100%;
  margin-bottom: 24px;
}

.modal-appointment_title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.0025em;

  /* Color/Gray scale/800 */
  color: #424242;
  text-align: initial;
}
.modal-appointment_content {
  display: flex;
  flex-direction: row;
  justify-content: initial;
  margin-left: 8px;
}

.modal-appointment_step {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */
  letter-spacing: 0.0025em;

  /* Color/Gray scale/800 */
  color: #424242;
}
.modal-appointment_circle {
  width: 4px;
  height: 4px;
  background: black;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto 4px;
  border-radius: 50%;
}

.modal-disclaimer_informative {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */
  letter-spacing: 0.0015em;

  /* Color/Gray scale/700 */
  color: #616161;
  margin: 8px 0px 8px 0px;
  text-align: initial;
}

@media (min-width: 200px) {
  .modal-main_informative {
    width: 270px;
  }
  .modal-title_informative {
    width: 98%;
  }
}

@media (min-width: 328px) {
  .modal-main_informative {
    width: 328px;
  }
  .modal-title_informative {
    width: 98%;
  }
}

@media (min-width: 700px) {
  .modal-main {
    width: 600px;
  }

  .modal-main-card-holder {
    height: 525px;
    width: 600px;
  }

  .modal-img {
    height: 264px;
    width: 100%;
  }

  .modal-content {
    padding: 32px;
  }

  .modal-title {
    font-size: 24px;
    height: 72px;
    line-height: 36px;
    width: 328px;
  }

  .modal-description {
    font-size: 16px;
    letter-spacing: 0.0025em;
    line-height: 24px;
  }

  .modal-price {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    margin-top: 24px;
  }

  .modal-discount {
    margin-bottom: 24px;
  }

  .modal-disclaimer {
    font-size: 16px;
    height: 24px;
    letter-spacing: 0.0015em;
    line-height: 24px;
    width: 477px;
  }

  /* -------------- INFORMATIVE MODAL TEXT DESKTOP ------------- */

  .modal-main_informative {
    width: 523px;
  }
  .modal-content_informative {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0px 38px 24px 38px;
  }
  .modal-title_informative {
    align-items: flex-start;
    color: #212121;
    font-family: "Work Sans Bold", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    height: 100%;
    line-height: 28px;
    width: 95%;
    text-align: initial;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    color: #212121;
    margin: 12px 0 9px 0;
  }

  .modal-subtitle_informative {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */
    display: flex;
    align-items: center;
    letter-spacing: 0.0015em;

    /* Color/Gray scale/800 */
    color: #424242;
    text-align: initial;
    margin-bottom: 24px;
    width: 85%;
  }

  .modal-description_informative {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.0025em;

    /* Color/Gray scale/800 */
    color: #424242;

    text-align: initial;
    margin-bottom: 8px;
  }

  .modal-description_informative span {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */
    letter-spacing: 0.0025em;

    /* Color/Gray scale/800 */
    color: #424242;
  }

  .modal-appointment_title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.0025em;

    /* Color/Gray scale/800 */
    color: #424242;
  }
  .modal-appointment_content {
    display: flex;
    flex-direction: row;
    justify-content: initial;
    margin-left: 24px;
  }

  .modal-appointment_step {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.0025em;

    /* Color/Gray scale/800 */
    color: #424242;
  }
  .modal-appointment_circle {
    width: 4px;
    height: 4px;
    background: black;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: auto 9px auto 4px;
    border-radius: 50%;
  }

  .modal-disclaimer_informative {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */
    letter-spacing: 0.0015em;
    
    /* Color/Gray scale/700 */
    color: #616161;
    
    margin: 8px 0px 8px 0px;
    text-align: initial;
  }
}

@media (max-width: 420px) {
  .modal-main-card-holder {
    height: 408px;
  }
}
