.ContainerDates {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.ContainerLoader {
  margin-top: 100px;
}

.discount{
  width: 20px;
  left: 10px;
  top: 3px;
  position: relative;
}

.CenterContainer {
  margin-top: 42px;
  display: flex;
  width: 100%;
  max-width: 280px;
  padding: 16px 27px 11px;
  border-radius: 10px;
  border: solid 1.3px #003fff;
  background-color: #ffffff;
  outline: none;
}

.Select {
  margin: 40px 0 0;
  width: 100%;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.Timing {
  margin-top: 10px;
  width: 100%;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #003fff;
}


.AddressContainer {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.Address {
  margin: 4px 0 0;
  font-family: ModernEra;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: left;
  color: #707070;
}

.Center {
  margin: 0;
  font-family: ModernEraBold;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #003fff;
  padding: 4px 8px;
}

.Icon {
  height: 40px;
  width: 40px;
}

.DatesContainer {
  margin-top: 40px;
  width: 100%;
}

.Label {
  text-transform: capitalize;
}

.AllDates {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.ImageArrow {
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
}

.ContainerDesktop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
  margin-top: 5px;
}

.bookingPriorityContainer{
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.priorityDatesContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
  margin-top: 5px;
}

.ContainerMobile {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
}

.Item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Item > p{
  margin: 0 0 12px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
}

.Time {
  margin-top: 20px;
  padding: 8px 36px;
  font-family: ModernEra;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  border-radius: 10px;
  box-shadow: 4px 8px 8px 0 #d2ddff;
  background-color: #ffffff;
  outline: none;
  cursor: pointer;
}


.ContainerTimes {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0 48px;
  max-width: 557px;
}

.ImageArrow img{
  width: 7.3px;
  height: 12.7px;
  outline: none;
}

.ImageArrow {
  width: 10.3px;
  height: 10.3px;
  outline: none;
}

.Back {
  display: flex;
  position: absolute;
  left: 25px;
  outline: none;
  cursor: pointer;
  width: 200px;
}

.Left {
  margin-right: 1px;
}

.Right {
  margin-left: 1px;
}

.Back > p {
  margin: 0 0 0 6px;
  font-family: ModernEra;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #0752f9;
}

.TitleName {
  margin: 0;
  font-family: QuincyCF;
  font-size: 25px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.24;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
}

.EmptyDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  height: 396px;
  background-color: #f7f9ff;
}

.EmptyDate > p {
  margin: 25px 0 0;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDateDesk > p {
  margin: 0 5px;
  font-family: QuincyCF;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #1e1e1e;
  max-width: 222px;
}

.EmptyDate > img {
  margin-top: 58px;
}

.EmptyDateDesk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 396px;
  background-color: #f7f9ff;
}
.promoAppointmentContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.promoAppointmentTitleContainer{
  display: flex;
  flex-direction: row;
  align-content: space-between;
  justify-content: space-between;
  
}
.promoAppointmentTextContainer{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 8px;
width: 103px;
height: 24px;
background: #E7F3EE;
border-radius: 100px;

flex: none;
flex-grow: 0;
/* margin-left: 46%; */
}

.promoAppointmentTextContainer span{
width: 8px;
height: 8px;
background: #05A357;

flex: none;
order: 0;
flex-grow: 0;
margin: 0px 4px;
border-radius: 50%;
}

.promoAppointmentTextContainer p{
width: 75px;
height: 16px;
font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
letter-spacing: 0.004em;
color: #156A41;
flex: none;
order: 1;
flex-grow: 0;
margin: 0px 4px;
}

@media(max-width: 350px){  
  .EmptyDate {
    width: 260px;
  }

  .CenterContainer {
    padding: 16px 20px 11px;
  }
}


@media(min-width: 700px){

  .CenterContainer {
    max-width: 593px;
  }

  .ContainerDates {
    margin-bottom: 83px;
  }
}

.bookingTitle{
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 16px;
  margin-top: 18px;  
}

.bookingDescription{
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0.0125em;
  text-align: center;
  color: #616161; 
}

.bookingCta {
  text-decoration: none;
  border: none;
  border-radius: 10px;
  background: #0752F9;
  box-shadow: 0px 4px 4px rgba(7, 82, 249, 0.16);
  margin-top: 16.5px;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-family: Inter;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: center;
  width: 235px;
  padding: 10px 2px;
}

.bookingCtaDisabled {
  text-decoration: none;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgba(7, 82, 249, 0.16);  background-color: #87ABFB;
  margin-top: 17px;
  font-stretch: normal;
  font-style: normal;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.0125em;
  text-align: center;
  width: 267px;
  padding: 10px 2px;
}


.PopupCard {
  padding: 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 420px;
  width: 420px;
  margin: auto 24px;
  max-height: 50%;
}

.PopupCard img{
  margin-top: 10px;
  width: 77px;
}

.PopupTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 540;
  font-size: 28px;
  line-height: 48px;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin-top: 40px;
}

.PopupDesc {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 24px;
}

.PopupBtn {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 8px;
  background-color: #0752f9;
  font-family: 'Inter';
  font-weight: 520;
  font-size: 16px;
  line-height: 24px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 24px;
  max-width: 80%;
  width: 332px;
}

.PopupLink{
  margin-top: 24px;
  text-decoration: underline;
  cursor: pointer;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.0125em;
  text-align: left;

}

@media (max-width: 420px) {
  .PopupTitle {
    font-size: 23px;
  }
  .PopupCard{
    max-height: 100%;
  }
}
